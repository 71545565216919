import { lazy } from 'react';

export default [
    {
        path: '/taipeiff-actor-archive',
        exact: true,
        component: lazy(
            () => import(/* webpackChunkName: 'TaipeiffActorArchiveLayout' */ './layout'),
        ),
    },
];
